import React, { useState } from 'react';
import { Button, Space } from 'antd';
import { toast } from 'react-toastify';
import request from '../../../../../../logic/utils/request';
import Modal from '../../../../../../common/ui/Modal/Modal';
import RenderInputs from './RenderInputs';

function NewService({
  fetchServices, toggleOpen, isOpen, setServiceData,
  serviceData, serviceUpdate, preloader, showError, write, initialData,
}) {
  const [isValid, setIsValid] = useState(false);

  const resetModal = () => {
    toggleOpen();
    // setServiceData({ ...initialData });
    // setServiceUpdate(false);
  };

  const onUpdateServices = async (promise) => {
    if (isValid) {
      preloader();
      try {
        await promise;
        fetchServices();
      } catch (err) {
        showError(err.data.error);
      }
      preloader();
      resetModal();
    }
  };

  const addService = () => {
    request('/server/api/queue/', 'add', { ...serviceData })
      .then(() => {
        toast.success('Очередь создана');
        fetchServices();
        toggleOpen();
        setServiceData(initialData);
      })
      .catch((e) => {
        switch (e.data.error.code) {
          case '23':
            toast.error('Заполните обязательные поля');
            break;
          case '24':
            toast.error('Очередь с таким именем уже существует');
            break;
          default:
            toast.error('Ошибка создания очереди');
            console.error(e);
        }
      });
    // onUpdateServices();
  };

  const updateService = () => {
    const { id, ...body } = serviceData;
    onUpdateServices(request(`/server/api/queue/${id}/`, 'update', body));
  };

  const handleInput = (e) => {
    const { name: inputName, value } = e.target;
    setServiceData({
      ...serviceData,
      [inputName]: inputName === 'workWeek' ? { workWeekId: value } : value,
    });
    const {
      name, fullname, position, prefix,
    } = serviceData;
    setIsValid((name && fullname && position && prefix) || serviceUpdate);
  };

  return (
    <>
      {write && (
        <Button type="primary" size="large" onClick={toggleOpen}>
          Добавить
        </Button>
      )}
      <Modal title={serviceUpdate ? 'Редактирование услуги' : 'Добавление Услуги'} toggleOpen={resetModal} isOpen={isOpen}>
        <div style={{ width: '100%' }}>
          <RenderInputs
            setServiceData={setServiceData}
            serviceUpdate={serviceUpdate}
            handleInput={handleInput}
            serviceData={serviceData}
            preloader={preloader}
          />
          {write && (
          <Space direction="horizontal" size="large" style={{ justifyContent: 'center', width: '100%' }}>
            <Button type="primary" onClick={serviceUpdate ? updateService : addService}>
              Сохранить
            </Button>
            <Button onClick={resetModal} cancel>Отменить</Button>
          </Space>
          )}
        </div>
      </Modal>
    </>
  );
}

export default React.memo(NewService);
