import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import { Typography } from 'antd';
import { toast } from 'react-toastify';
import Axios from 'axios';
import TicketList from './components/TicketList/TicketList';
import WorkPlaceList from './components/WorkPlaceList/WorkPlaceList';
import QueuesList from './components/QueuesList/QueuesList';
import Statistics from './components/Statistic/Statistic';
import Graphic from './components/Graphic';
import request from '../../../../../logic/utils/request';
import Preloader from '../../../components/preloader/Preloader';

const { Title } = Typography;

function Monitoring() {
  const timeOut = useRef();
  const oldMPX = useRef();
  const oldMPY = useRef();
  const timeout = useRef();

  const [monitoringData, setMonitoringData] = useState([]);

  const fetchMonitoringData = useCallback(async () => {
    const monitoringAnswer = await request('/server/api/monitoring/', 'get', {});
    const graphdataAnswer = await request('/server/api/graphdata/', 'get', {});

    const data = {
      wplaces: monitoringAnswer.data.wplaces,
      tickets: monitoringAnswer.data.tickets,
      statistics: monitoringAnswer.data.statistics,
      queues: monitoringAnswer.data.queues,
      graphic: graphdataAnswer.data.hours,
    };
    setMonitoringData(data);
  }, []);

  useEffect(() => {
    fetchMonitoringData();
    let mausePositionX = 0;
    let mausePositionY = 0;
    timeout.current = setInterval(() => {
      document.addEventListener('mousemove', (e) => {
        mausePositionX = e.clientX;
        mausePositionY = e.clientY;
      });

      if (oldMPX.current === mausePositionX
        && oldMPY.current === mausePositionY) {
        timeOut.current += 5;
        if (timeOut.current < 15) fetchMonitoringData();
      } else {
        timeOut.current = 0;
        fetchMonitoringData();
      }
      oldMPX.current = mausePositionX;
      oldMPY.current = mausePositionY;
    }, 5000);
    return () => { clearInterval(timeout.current); };
  }, [fetchMonitoringData]);

  const userKick = async (row) => {
    const token = window.localStorage.getItem('token');
    const responce = await Axios({
      method: 'post',
      url: `/server/api/kick-workplace/?action=none&token=${token}`,
      data: { wplace_id: row.id },
    });
    if (responce.data.status === 'ok') {
      toast.success('Готово', 5000);
    } else {
      toast.error('Оператор занят!', 5000);
    }
  };

  return (
    <>
      <Title style={{ textAlign: 'center', color: '#25547A' }}>Мониторинг</Title>
      {monitoringData.length !== 0 ? (
        <>
          <Statistics data={monitoringData.statistics} />
          <Graphic data={monitoringData.graphic} />
          <TicketList data={monitoringData.tickets} />
          <QueuesList data={monitoringData.queues} />
          <WorkPlaceList data={monitoringData.wplaces} userKick={userKick} />
        </>
      )
        : <Preloader isOpen small />}

    </>
  );
}

export default Monitoring;
