import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory, { PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import { TableBox, PaginationBox } from './Table.style';

function Table(props) {
  const {
    data,
    columns,
    handleIcon,
    keys,
    onTableChange,
    pagination,
    remote,
    disablePagination,
    expandRow,
    cellEdit,
    doubleColumn,
    noSpanMargin,
  } = props;
  const rowEvents = { onClick: handleIcon || (() => false) };
  const rowClasses = 'tableTD';

  const showAllCnt = () => {
    let allCnt = 0;
    if (data.length > 0) {
      if (data[0].total_rows_count) {
        allCnt = Number(data[0].total_rows_count);
      } else {
        allCnt = data.length;
      }
    }
    return (allCnt);
  };

  const options = {
    custom: true,
    hidePageListOnlyOnePage: true,
    hideSizePerPage: false,
    alwaysShowAllBtns: false,
    totalSize: data && data.length,
    // sizePerPage: `${disablePagination ? data.length : 10}`,
    sizePerPageList: [{
      text: '10', value: 10,
    }, {
      text: '30', value: 30,
    }, {
      text: '50', value: 50,
    }, {
      text: 'Все', value: showAllCnt(),
    }],
  };

  return (
    <TableBox
      doubleColumn={doubleColumn}
      noSpanMargin={noSpanMargin}
    >
      <PaginationProvider
        pagination={paginationFactory({ ...options, ...pagination })}
      >
        {({
          paginationProps,
          paginationTableProps,
        }) => (

          <div>
            <BootstrapTable
              {...paginationTableProps}
              keyField={keys}
              id="table-to-xls"
              remote={remote ? { pagination: true, filter: true, sort: true } : null}
              data={data}
              columns={columns}
              filter={filterFactory()}
              bordered={false}
              cellEdit={cellEdit || {}}
              rowEvents={rowEvents}
              rowClasses={rowClasses}
              onTableChange={onTableChange || (() => {})}
              expandRow={expandRow || undefined}
            />
            {disablePagination ? null : (
              <PaginationBox bottom>
                <PaginationListStandalone
                  {...paginationProps}
                />
                <SizePerPageDropdownStandalone
                  {...paginationProps}
                />
              </PaginationBox>
            )}
          </div>
        )}
      </PaginationProvider>
    </TableBox>
  );
}
export default Table;
