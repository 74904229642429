import { Button, Checkbox, Input } from 'antd';
import React from 'react';

export default null;

const onChangeImg = (changeImage) => () => {
  const input = document.createElement('input');

  input.type = 'file';
  input.style.display = 'none';
  input.onchange = (event) => {
    if (event.target.files.length <= 0) return;
    const file = new FileReader();
    const typeFile = event.target.files[0].type;
    if (typeFile !== 'image/png' && typeFile !== 'image/jpeg') return;
    file.onload = (e) => {
      changeImage(e.target.result, 'logotype');
    };
    file.readAsDataURL(event.target.files[0]);
    document.body.removeChild(input);
  };

  document.body.appendChild(input);
  input.click();
};

export function TableImagesConfig(change, changeImage) {
  return [
    {
      title: 'Наименование',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Высота',
      dataIndex: 'height',
      key: 'height',
      render: (height, image) => (
        <Input
          type="number"
          value={height}
          style={{ width: 70 }}
          onChange={(e) => change(image.key, e.target.value, 'height')}
        />
      ),
    },
    {
      title: 'Изображение',
      dataIndex: '',
      key: '',
      render: () => (
        <Button type="primary" onClick={onChangeImg(changeImage)}>Выбрать</Button>
      ),
    },
    {
      title: 'Вырав. по центру',
      dataIndex: 'align',
      key: 'align',
      render: (align, image) => (
        <Checkbox
          checked={align}
          onChange={(e) => change(image.key, e.target.checked, 'align')}
        />
      ),
    },
    {
      title: 'X',
      dataIndex: 'x',
      key: 'x',
      render: (x, image) => (
        <Input
          type="number"
          size="small"
          value={x}
          style={{ width: 70 }}
          onChange={(e) => change(image.key, e.target.value, 'x')}
        />
      ),
    },
    {
      title: 'Y',
      dataIndex: 'y',
      key: 'y',
      render: (y, image) => (
        <Input
          type="number"
          size="small"
          value={y}
          style={{ width: 70 }}
          onChange={(e) => change(image.key, e.target.value, 'y')}
        />
      ),
    },
    {
      title: 'Показать',
      dataIndex: 'show',
      key: 'show',
      render: (show, image) => (
        <Checkbox
          checked={show}
          onChange={(e) => change(image.key, e.target.checked, 'show')}
        />
      ),
    },
  ];
}
