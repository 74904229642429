import React from 'react';
import { useParams } from 'react-router-dom';
import { Provider } from 'react-redux';
import App from './App';
import store from './store/store';
import './fonts/fonts.css';

function Terminal({
  id, config, screen,
}) {
  const { id: paramsId } = useParams();

  return (
    <div
      className="devices__preview_box"
      style={{
        transformOrigin: 'left top',
      }}
    >
      <Provider store={store}>
        <App oldConfig={config} screen={screen} id={id || paramsId} />
      </Provider>
    </div>
  );
}

export default React.memo(Terminal);
