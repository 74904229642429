import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
  MainConent, LayoutBox,
} from '../../common/ui/Common';
import Header from './layout/Header';
import SideNav from './layout/SideNav';
import Routes from './router-function/Routes';
import { LayoutContainer } from './layout/style';

function Layout() {
  return (
    <LayoutContainer>
      <SideNav />
      <LayoutBox>
        <Header />
        <MainConent>
          <Routes />
        </MainConent>
      </LayoutBox>
    </LayoutContainer>
  );
}

export default Layout;
