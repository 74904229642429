import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import * as ringring from '../images/tuturu.mp3';
import seachByKey from '../../../private/subpages/statistics/reports/utils/seachByKey';
import { useScreenProviderContext } from '../use/useScreenProviderContext';

function TicketProcess() {
  const Ticket = localStorage.getItem('currentTicketNumber');
  const Operatorfio = localStorage.getItem('operatorfio');
  const placename = localStorage.getItem('placename');
  const placedesc = JSON.parse(localStorage.getItem('placedesc'));
  const { setShowPreloader } = useScreenProviderContext();

  const [placeDesc, setPlaceDesc] = useState([]);

  const getSoundList = async () => {
    const line = [];
    try {
      const res = await axios({
        method: 'get',
        url: '/api/soundlist',
      });
      const { data: { sounds } } = res;

      const wnums = placename.split(',');
      placedesc.forEach((el, index) => {
        const sound = seachByKey(sounds, 'value', el.place);
        if (sound) line.push(`${sound.name} ${wnums[index]}`);
      });
      setPlaceDesc(line);
    } finally {
      setShowPreloader(false);
    }
  };

  useEffect(() => {
    setShowPreloader(true);
    getSoundList();
    const audio = new Audio('../images/tuturu.mp3');
    audio.play();
  }, []);

  const getLocation = () => placeDesc.map((el) => <p key={el}>{el}</p>);

  return (
    <div className="ticketProcess">
      <div className="ticketProcess__container">
        <p className="ticketProcess__ticketNumber">{Ticket}</p>
        <p className="ticketProcess__text">Вас вызывают</p>
        <p className="ticketProcess__subText">Пожалуйста, пройдите</p>
        <div className="ticketProcess__okno">{getLocation()}</div>
        <p className="ticketProcess__subText">Ваш оператор:</p>
        <p className="ticketProcess__text">{Operatorfio}</p>
      </div>
    </div>
  );
}

export default TicketProcess;
